import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

// 引入router
import router from './router'

// 引入vuex
import store from './store/index.js';

// 引入element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import ResetMessage from './plugins/resetMessage.js'
Vue.prototype.$message = ResetMessage

// 引入axios
import $http from './plugins/axios.js'
Vue.prototype.$http = $http

// 引入api
import API from './API/api.js'
Vue.prototype.API = API

// 挂载文件上传服务
import { fileUpload, errImg } from './plugins/file_upload.js'
Vue.prototype.fileUpload = fileUpload
Vue.prototype.errImg = errImg

// 引入颜色插件
// import vcolorpicker from 'vcolorpicker'
// Vue.use(vcolorpicker)
// 注册颜色插件
import ColorPicker from './components/color-picker/color-picker.vue'
Vue.component('colorPicker', ColorPicker)
// 挂载 vue-worker
import VueWorker from 'vue-worker'
Vue.use(VueWorker)

// 引入图片懒加载
import vueLazy from 'vue-lazyload'
Vue.use(vueLazy, {
  error: require('@/assets/images/avatar.png'),
  loading: require('@/assets/images/avatar.png')
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')