import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter)

const routes = [
  {
    path: '/container',
    name: '首页',
    component: () => import('@/pages/MakeServer/makeServer.vue')
  },
  {
    path: '/typesetting',
    name: '制作',
    component: () => import('@/pages/Typesetting/typesetting.vue')
  },
  {
    path: '/login',
    name: '登录',
    component: () => import('@/pages/Login/login.vue')
  }, 
  {
    path: '/regist',
    name: '注册',
    component: () => import('@/pages/Regist/regist.vue')
  }, 
  {
    path: '/',
    name: '首页',
    component: () => import('@/pages/Container/container.vue')
  }
]

export default new VueRouter({
  // mode: 'history',
  mode: 'hash',
  routes
})