<template>
  <div id="app">
    <div class="fileTitle">{{ !emptyFileTitle ? fileTitle : '' }}</div>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'App',
  computed: {
    ...mapState(['fileTitle', 'emptyFileTitle'])
  },
  created() {
    // 基准大小
    const baseSize = 16
    // 设置 rem 函数
    function setRem() {
      // 当前页面宽度相对于 750 宽的缩放比例，可根据自己需要修改。
      const width = document.documentElement.clientWidth <= 1440 ? 1400 : document.documentElement.clientWidth
      const height = window.screen.height 
      const scale = height == 1080 ? 1 : width / 1920

      console.log('scale', scale)
      // 设置页面根节点字体大小 Math.min(scale, 2)
      document.documentElement.style.fontSize = (baseSize * scale.toFixed(2)) < 14 ? '14px' : baseSize * scale.toFixed(2) + 'px'
    }
    // 初始化
    setRem()
    // 改变窗口大小时重新设置 rem
    window.onresize = function() {
      setRem()
      
      // 获取当前页面所有el-table
      let el_table = document.querySelectorAll('.el-table')
      for(let i = 0; i < el_table.length; i++) {
        var cur_table = el_table[i],
        height = cur_table.parentNode.offsetHeight

        cur_table.style.height = height + 'px'
      }
    }
  }
}
</script>

<style lang='less'>
body {
  margin: 0;
  -moz-user-select:none; /*火狐*/
  -webkit-user-select:none; /*webkit浏览器*/
  -ms-user-select:none; /*IE10*/
  -khtml-user-select:none; /*早期浏览器*/
  user-select:none;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;

  .fileTitle {
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
  }
}

.el-table--border:after,
.el-table--group:after,
.el-table:before {
  background-color: #999 !important;
  z-index: 99 !important;
}

.el-table--border,
.el-table--group {
  border-color: #999 !important;
}

.el-table td,
.el-table th.is-leaf {
  border-bottom: 1px solid #999 !important;
}

.el-table--border th,
.el-table--border th.gutter:last-of-type {
  border-bottom: 1px solid #999 !important;
}

.el-table--border td,
.el-table--border th {
  line-height: 1px !important;
  border-right: 1px solid #999 !important;
  padding: 7px 0 !important;
}

.el-table--border td:first-child .cell {
  padding-left: 14px !important;
}

.el-table .cell {
  width: 100%;
  font-size: 12px;
	box-sizing: border-box;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap !important;
	display: inline-block;
}

.el-dialog {
  margin-right: 200px !important;
}

.pagin {
  width: 100%;
  padding-top: 10px;
  display: flex;
  justify-content: center;
}
.dialog {
  max-height: calc(100vh - 160px);
  box-sizing: border-box;
  min-width: 550px;
}

.el-dialog__body {
  max-height: calc(100vh - 214px) !important;
  box-sizing: border-box !important;
  padding: 0 !important;

  & > div {
    max-height: calc(100vh - 214px) !important;
    width: 100%;
    height: 100%;
    padding: 10px 20px;
    box-sizing: border-box;
    overflow: auto;
  }
}

.dialog-btn {
  width: 100%;
  background-color: #eee;
  text-align: center;
  padding: 20px 0;
  box-sizing: border-box;
  border-radius: 0 0 2px 2px;
}

.el-dialog--center {
  margin-top: 90px !important;
}


// 上传图片
.avatar-uploader {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;

  .self-explain {
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;

    div {
      width: 100%;
      text-align: left;
      line-height: 25px;
    }
  }

  .avatar {
    width: 200px;
    height: 132px;
    border-radius: 5px;
    margin-right: 20px;
    border: 1px dashed #999;
    cursor: pointer;
    position: relative;

    & > img {
      width: 100%;
      height: 100%;
      border: 7px;
    }
  }
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  padding-right: 8px;
}

.tree-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.el-dialog__wrapper {
  overflow: hidden;
}

.right-10px {
  margin-right: 10px;
}
</style>
