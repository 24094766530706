const gzKey = `https://service-ij14wquc-1305256445.gz.apigw.tencentcs.com`
const api = '/api'
const server = `https://www.hzics.com/adminapi/admins`

const cardUrl = `https://service-b2pdlkry-1305256445.gz.apigw.tencentcs.com/release`
// const cardUrl = `http://192.168.1.4:9000`
// const server = `http://192.168.1.5:19522/admins`

export default {
  uploadSizeMax: `${cardUrl}/createCosToken`,
  makeCardToPdf: filePath => `${cardUrl}/generateCard?filePath=${filePath}`, // 生成pdf

  /**
   * 登录配置项
   */
  findCardAdminImg: `${cardUrl}/findCardAdminImg`, // 查询登录配置数据

  /**
   * 注册 
   */
  smsCheckedCode: phone => `${gzKey}/release/smsCheckedCodeForRegister?phone=${phone}&intefase=smsCardForRegister`, // 获取验证码
  register: `${cardUrl}/release/cardApply`, // 注册
  getCustomType: `${server}/homeCardSystem`, // 获取注册版本号

  /**
   * 登录 
   */
  loginByPhone: `${gzKey}/loginByPhone?interface=card`, // 验证码 - 登录
  verificationCode: phone => `${gzKey}/release/smsCheckedCodeForRegister?phone=${phone}&intefase=verificationCardCode`, // 获取登录验证码
  loginByLoginNameAndPassword: `${cardUrl}/loginByLoginNameAndPassword`, // 账号密码登录
  // updatePassword: `${gzKey}/release/updatePassword?intefaces=card`, // 修改账号密码
  initLoginNameAndPassword: `${cardUrl}/initLoginNameAndPassword`, // 初始化用户登录信息
  getCustomer: `${cardUrl}/getCustomer`, // 获取公司信息

  findJurisdiction: `${cardUrl}/findJurisdiction`, // 获取权限

  uploadFile: `${gzKey}/release/uploadFile?`, // 上传图片 (问号不能去掉)
  echoImage: (id) => {
    let url = `https://backgroupcard-1305256445.cos.ap-guangzhou.myqcloud.com`,
    companyId = JSON.parse(localStorage.getItem('loginInfo')).companyId
    
    return `${url}/${companyId}card/Card/${id}`
  }, // 回显证卡图片
  echoFace: (id, modelId) => {
    let url = `https://mybucket-1305256445.cos.ap-guangzhou.myqcloud.com`,
    companyId = JSON.parse(localStorage.getItem('loginInfo')).companyId

    return `${url}/${modelId}card/HeadFile/${id}`
  }, // 回显人员图片

  // element: `${gzKey}/release/generateCard`, // 保存证卡
  getAllCardTag: `${cardUrl}/getAllCardTag`, // 查询证卡类别
  searchCompany: name => `${cardUrl}/searchCompany?name=${name}`, // 根据公司名称搜索公司
  sendOut: (backgroupId, customerId) => `${cardUrl}/sendOut?backgroupId=${backgroupId}&customerId=${customerId}`, // 发送证卡于选中公司 
  finalize: backgroupId => `${cardUrl}/finalize?backgroupId=${backgroupId}`, // 定稿

  generateCard: intefaces => `${cardUrl}/${intefaces}`, // 保存模板(saveModel), 修改(updateModel), 保存文件 (saveElementAndUser)
  selectModel: (pageNum, pageSize, intefaces) => `${cardUrl}/${intefaces}?size=${pageSize}&num=${pageNum}`, // 查询模板(selectModel), 查询文件(selectElementAndUser)
  delectModel: id => `${cardUrl}/delectModel?id=${id}`, // 删除模板

  meetingsPageAndStateCode: `${cardUrl}/cardFindMeeting?stateCode=4&pageSize=9999&pageNum=1&searchKey=`, // 查询会议
  meetings: `${cardUrl}/meetings?pageNum=1&pageSize=9999&searchKey=`, // 

  /**
   * 人员
   */
  addUser: id => `${cardUrl}/saveUser?backgroupId=${id}`, // 添加人员, 删除人员
  conditionQuerys: (deparmentId, pageNum, pageSize, externalCode, searchKey, photoFlag, intefaceType, id) => `${cardUrl}/conditionQuerys?deparmentId=${deparmentId}&pageNum=${pageNum}&pageSize=${pageSize}&externalCode=${externalCode}&searchKey=${searchKey}&photoFlag=${photoFlag}&intefaceType=${intefaceType}&backgroupId=${id}`, // 查询人员
  updateUser: id => `${cardUrl}/saveUser?backgroupId=${id}`, // 修改人员
  delHzUser: id => `${cardUrl}/delHzUser?backgroupId=${id}`, // 删除勾选人员
  delectAllUser: id => `${cardUrl}/delectAllUser?backgroupId=${id}`, // 删除全部人员
  singleClomnData: (id, backgroupId) => `${cardUrl}/findPersonById?id=${id}&backgroupId=${backgroupId}`, // 查询人员 - 单条数据 
  
  fileExcel: (name, type, id) => `${cardUrl}/excel?actionCode=0&fileName=${name}&type=${type}&intefaces=card&backgroupId=${id}`, // 上传excel人员

  photoMatching: id => `${cardUrl}/photoMatching?backgroupId=${id}`, // 匹配相片
  findDuplicateName: (pageNum, pageSize, externalCode, id) => `${cardUrl}/findDuplicateName?num=${pageNum}&size=${pageSize}&externalCode=${externalCode}&backgroupId=${id}`, // 重名人员

  addDepartmentForCompany: id => `${cardUrl}/addDepartmentForCompany?backgroupId=${id}`, // 添加部门
  findCompanyDepartment: id => `${cardUrl}/findCompanyDepartment?backgroupId=${id}`, // 获取部门
  delDpeartmentForCompany: id => `${cardUrl}/delDpeartmentForCompany?backgroupId=${id}`, // 删除部门
  editDepartmentNameForCompany: id => `${cardUrl}/addDepartmentForCompany?backgroupId=${id}`, // 修改部门

  findConfereeGroupAllByMeetingId: meetId => `${cardUrl}/findConfereeGroupAllByMeetingId?meetingId=${meetId}`, // 查询参会人分组
  findByMeetingIdAndPage: (meetId, groupId, pageNum, pageSize, searchKey = '', externalCode = 2, add = '') => `${cardUrl}/findByMeetingIdAndPage?intefaces=findByMeetingIdAndPage&meetingId=${meetId}&confereeGroupId=${groupId}&pageNum=${pageNum}&searchKey=${searchKey}&pageSize=${pageSize}&statuslCode=&report=2&externalCode=${externalCode}&add=${add}`, // 参会分组查询人员  
  meetingToCardUser: id => `${cardUrl}/meetingToCardUser?backgroupId=${id}`, // 会议导入人员

  matchingUserPhotoFiles: (type, fileName, id) => `${gzKey}/release/matchingUserPhotoFiles?type=${type}&fileName=${fileName}&intefaces=card&backgroupId=${id}`, // 批量上传头像

  matchingUserPhoto: (userId, fileInfoId) => `${cardUrl}/matchingUserPhoto?userId=${userId}&fileInfoId=${fileInfoId}`, // 关联人员与相片
  getNotMatchingPhoto: (id, pageNum, pageSize) => `${cardUrl}/getNotMatchingPhoto?num=${pageNum}&size=${pageSize}&backgroupId=${id}`, // 查询相片匹配 相片
  findAllUserNoPhotoForCompany: (id, type, pageNum, pageSize, search) => `${cardUrl}/findAllUserNoPhotoForCompany?type=${type}&num=${pageNum}&size=${pageSize}&searchKey=${search}&backgroupId=${id}`, // 查询相片匹配 人员
  deleteNotMatchingPhoto: id => `${cardUrl}/deleteNotMatchingPhoto?backgroupId=${id}`, // 删除多余的照片

  downloadExcelLog: `${cardUrl}/downloadExcelLog`, // 下载日志

  /**
   * 排版
   */
  typeselect: `${cardUrl}/typeselect`, // 查询
  typeadd: intefase => `${cardUrl}/${intefase}`, // 添加(add), 修改(save)
  typedel: id => `${cardUrl}/typedel?id=${id}`, // 删除
  findCardBySize: `${cardUrl}/findCardBySize`, // 查询同等尺寸证卡

  /**
   * 修改密码  
   * */
  updatePass: phone => `${gzKey}/release/smsCheckedCodeForRegister?phone=${phone}&intefase=cardUpdate`, // 获取验证码
  cardUpdate: `${cardUrl}/cardUpdate`, // 修改密码

  /**
   * 地址管理
   */
  getCity: `${cardUrl}/getCity`, // 获取城市 三级联动
  addHarvestAddress: `${cardUrl}/addHarvestAddress`, // 添加地址数据
  findHarvestAddress: `${cardUrl}/findHarvestAddress`, // 查询地址数据
  delectHarvestAddress: id => `${cardUrl}/delectHarvestAddress?id=${id}`, // 删除地址数据
  updateAddressDefault: id => `${cardUrl}/updateAddressDefault?id=${id}`, // 修改默认地址

  sendCardDataToAdmin: `${cardUrl}/sendCardDataToAdmin`, // 发送
  getSendCardStatu: (pageNum, pageSize) => `${cardUrl}/getSendCardStatu?num=${pageNum}&size=${pageSize}`, // 发送记录
}