import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    /**
     * 权限：
     *    templateMaking - 样式设计
     *    dataInput - 数据输入
     *    templateOutput - 排版输出
     */
    templateMaking: 0,
    dataInput: 0,
    templateOutput: 0,

    /**
     * 会议管理
     */
    meetingData: {}, // 当前会议数据

    /**
     * 变量匹配值
     */
    attribute: [
      { prop: 'userName', label: '姓名'},
      { prop: 'photoFileId', label: '头像', img: true},
      { prop: 'phone', label: '手机号码'},
      { prop: 'departmentName', label: '部门'},
      { prop: 'characterId', label: '角色'},
      { prop: 'attribute1', label: '组别'},
      { prop: 'shouldArrive', label: '应到人数'},
      { prop: 'attendance', label: '已到人数'},
      { prop: 'notYet', label: '未到人数'},
      { prop: 'meetingName', label: '会议名称'},
    ],

    /**
     * 当前证卡模板
     */
    curCardModel: {},

    modelId: null,

    
    // 制作证卡 人员数据
    cardPerson: [],
    
    // 文件名称
    fileTitle: '', 
    emptyFileTitle: false, 
    // 当前证卡类别
    curCardType: '', 
    // 证卡类别
    cardTypeList: []
  },
  mutations: {
    // 设置会议数据
    setMeetingData(state, data){
      state.meetingData = data
    },

    // 设置证卡模板数据
    setCurCardModel(state, data) {
      state.curCardModel = data
    },

    // 更新模板id
    updateModelId(state, data) {
      console.log('modelId---', data)
      state.modelId = data
    },

    // 更新证卡 人员数据
    updateCardPerson(state, data) {
      state.cardPerson = data
    },
    
    // 更新文件名称
    updateFileTitle(state, data) {
      state.fileTitle = data
    },
    updateEmptyFileTitle(state, data) {
      state.emptyFileTitle = data
    },
    
    // 更新证卡类别列表
    updateCardTypeList(state, data) {
      state.cardTypeList = data
    },
    // 更新当前证卡类别
    updateCurCardType(state, data) {
      state.curCardType = data
    },
  },
  actions: {
  }, 
  modules: {
  },
});
